import React from "react";
import { shape, object, array, func, string, number } from "prop-types";

import AuthorBio from "../../molecules/author-bio";
import StoriesWithAd from "../../rows/stories-with-ad";
import Headline from "../../atoms/headline";
import AdWrapper from "../../rows/ads";
import { LoadMoreAuthorStories } from "../../molecules/load-more-author-stories";

import "./author.m.css";

export const AuthorPage = ({ data, config }) => {
  const { author, stories } = data;
  const headline = `Stories by '${author.name}'`;

  return Object.keys(author).length === 0 ? (
    <div styleName="container">
      <Headline headline="Author not found !" headerLevel={2} fontStyle="sans" />
    </div>
  ) : (
    <div styleName="container">
      <AuthorBio authorObj={author} />
      <div styleName="headline-container">
        <Headline headline={headline} headerLevel={2} />
      </div>
      <LoadMoreAuthorStories
        template={MoreCollectionStories}
        authorID={author.id}
        data={{ collection: null, stories: stories, config: config }}
        numStoriesToLoad={8}
        storiesPerPage={10}
        params={{ "item-type": "story" }}
      />
    </div>
  );
};

const MoreCollectionStories = ({ collection, stories, onLoadMore, noMoreStories, storiesPerPage, config }) => {
  return (
    <div styleName="wrapper">
      {stories.length ? (
        <StoriesWithAd
          stories={stories}
          isStickyAd={true}
          loadMore={onLoadMore}
          noMoreStories={noMoreStories}
          storiesPerPage={storiesPerPage}
          withAd
          SideBarAdcompoenent={<AdWrapper name="doubleRhs1" id="author-sidebar-double" />}
        />
      ) : (
        <h3>No stories found...</h3>
      )}
    </div>
  );
};

MoreCollectionStories.propTypes = {
  stories: object,
  collection: object,
  onLoadMore: func,
  noMoreStories: string,
  config: object,
  storiesPerPage: number
};

AuthorPage.propTypes = {
  data: shape({
    author: object,
    stories: array
  }),
  config: object
};
