import React from "react";
import { object, array, number, string } from "prop-types";
import "./card-image.m.css";
import DefaultImage from "../../../../assets/images/default-image.jpg";
import { ResponsiveImage, WithLazy } from "@quintype/components";
import assetify from "@quintype/framework/assetify";
import Video from "../icons/video";
import cx from "classnames";

const CardImage = ({ story = {}, type = "", alternativeImage, aspectRatio, alternativeImageMetadata, widths }) => {
  const storyObject = story.story ? story.story : story;
  const wrapperStyleName = cx(
    "qt-image-dynamic",
    { image16x9: type === "image16x9" },
    { image4x3: type === "image4x3" },
    { image1x1: type === "image1x1" }
  );
  const slug = alternativeImage || storyObject["hero-image-s3-key"];
  if (!slug) {
    return <PlaceholderImage />;
  }
  return (
    <WithLazy margin="50px">
      {() => (
        <figure styleName={wrapperStyleName}>
          {/* Desktop WebP */}
          <ResponsiveImage
            slug={alternativeImage || storyObject["hero-image-s3-key"]}
            metadata={alternativeImageMetadata || storyObject["hero-image-metadata"]}
            aspectRatio={aspectRatio}
            widths={widths}
            styleName="image"
            className="image"
            alt={storyObject.headline}
          />
          {storyObject["story-template"] === "video" && <Video className="video-logo" />}
        </figure>
      )}
    </WithLazy>
  );
};

export default CardImage;

CardImage.propTypes = {
  story: object,
  aspectRatio: array,
  widths: array,
  defaultWidth: number,
  type: string,
  alternativeImageMetadata: string,
  alternativeImage: string
};

const PlaceholderImage = () => (
  <div styleName="default-image">
    <WithLazy margin="50px">{() => <img src={assetify(DefaultImage)} alt="default-image" />}</WithLazy>
  </div>
);
