import React from "react";
import { object, number, array, func, bool, string } from "prop-types";
import StoryCard1 from "../../molecules/story-card-1";
import { LoadMoreCollectionStories } from "@quintype/components";
import LoadMoreButton from "../../atoms/load-more";
import AdWrapper from "../ads";
import "./load-more-mobile.m.css";

const getStories = (stories, ads, currentPath) => {
  return stories.map((story, key) => {
    return (
      <React.Fragment key={key}>
        <StoryCard1
          story={story}
          currentPath={currentPath}
          headerStyle="stories-with-ad-mobile-headline"
          headerLevel={2}
        />
        {ads[key] ? (
          <div styleName="rhs-ad">
            <AdWrapper name={ads[key].name} id={ads[key].id} />
          </div>
        ) : null}
      </React.Fragment>
    );
  });
};

getStories.propTypes = {
  stories: array,
  ads: array
};

export const StoriesWithAdMobile = ({ stories, loadMore, noMoreStories, storiesPerPage, ads, currentPath }) => {
  return (
    <React.Fragment>
      <div>{stories.length ? getStories(stories, ads, currentPath) : null}</div>
      <div>
        {stories.length >= storiesPerPage && !noMoreStories ? (
          <div styleName="button-wrapper">
            <LoadMoreButton onClick={loadMore} />
          </div>
        ) : null}
      </div>
    </React.Fragment>
  );
};

StoriesWithAdMobile.propTypes = {
  stories: array,
  loadMore: func,
  noMoreStories: bool,
  storiesPerPage: number,
  ads: array,
  currentPath: string
};

const MoreCollectionStories = ({ stories, onLoadMore, noMoreStories, storiesPerPage, ads, currentPath }) => {
  return (
    <div styleName="wrapper">
      {stories.length ? (
        <StoriesWithAdMobile
          stories={stories}
          loadMore={onLoadMore}
          noMoreStories={noMoreStories}
          storiesPerPage={storiesPerPage}
          ads={ads}
          currentPath={currentPath}
        />
      ) : (
        <h3>No stories found...</h3>
      )}
    </div>
  );
};

MoreCollectionStories.propTypes = {
  stories: array,
  onLoadMore: func,
  noMoreStories: bool,
  storiesPerPage: number,
  ads: object,
  currentPath: string
};

const LoadMoreMobile = ({ collection, numStoriesToLoad = 6, ads = [], loadStories, currentPath }) => {
  return (
    <div>
      {loadStories ? (
        <LoadMoreCollectionStories
          template={MoreCollectionStories}
          collectionSlug={collection.slug}
          data={Object.assign(
            {},
            { collection },
            { stories: [...loadStories.map(({ story }) => story).slice(0, numStoriesToLoad)] },
            { storiesPerPage: numStoriesToLoad },
            { ads },
            { currentPath }
          )}
          numStoriesToLoad={numStoriesToLoad}
          params={{ "item-type": "story" }}
        />
      ) : null}
    </div>
  );
};

LoadMoreMobile.propTypes = {
  collection: object,
  numStoriesToLoad: number,
  storiesPerPage: number,
  ads: object,
  currentPath: string,
  loadStories: array
};

export default LoadMoreMobile;
