import React from "react";

const Message = () => (
  <React.Fragment>
    <path d="M0 0h40v40H0z" />
    <path
      fill="#FFF"
      fillRule="nonzero"
      d="M30.57 16.957c.086-.086.18-.1.28-.043.1.057.15.143.15.258v8.766c0 .572-.2 1.06-.602 1.46a1.989 1.989 0 0 1-1.46.602H11.061c-.572 0-1.06-.2-1.46-.602A1.989 1.989 0 0 1 9 25.938v-8.766c0-.115.05-.193.15-.236a.278.278 0 0 1 .28.021c.945.716 3.15 2.335 6.617 4.856l.43.386c.687.516 1.231.888 1.632 1.117.688.373 1.318.559 1.891.559s1.203-.2 1.89-.602c.43-.229.975-.601 1.633-1.117l.43-.343c3.38-2.435 5.586-4.054 6.617-4.856zM20 22.5c-.372 0-.83-.172-1.375-.516-.315-.171-.76-.487-1.332-.945l-.43-.3c-3.523-2.579-5.915-4.355-7.176-5.329l-.3-.215A.98.98 0 0 1 9 14.38v-.816c0-.573.2-1.06.602-1.461a1.989 1.989 0 0 1 1.46-.602h17.875c.573 0 1.06.2 1.461.602.401.4.602.888.602 1.46v.817a.98.98 0 0 1-.387.816l-.215.172c-1.231.974-3.652 2.765-7.261 5.371l-.43.301c-.573.458-1.017.774-1.332.945-.544.344-1.003.516-1.375.516z"
    />
  </React.Fragment>
);

export default Message;
