import React from "react";
import { array, string, object } from "prop-types";
import cx from "classnames";

import TabWithIcon from "../../atoms/tab-with-icon";

import "./showcase-tabs.m.css";

import { recentTab } from "./customTabs";

class ShowcaseTabs extends React.PureComponent {
  state = {
    mobileDropDown: false
  };

  toggleMobileDropDown = () => {
    this.setState({ mobileDropDown: !this.state.mobileDropDown });
  };

  render() {
    const { tabs, current } = this.props;
    const { mobileDropDown } = this.state;

    const tabStyle = cx("tabs", { "is-active": mobileDropDown });

    return (
      <React.Fragment>
        <div styleName="title">Browse By</div>
        <div styleName="mobile">
          <TabWithIcon
            item={current || recentTab}
            active
            background={"gray"}
            toggleMobileDropDown={this.toggleMobileDropDown}
          />
        </div>

        <div styleName={tabStyle}>
          <TabWithIcon item={recentTab} active={!current} />
          {tabs.map((item, index) => (
            <TabWithIcon
              item={item}
              key={index}
              active={current && current.slug === item.slug}
              setActiveTab={this._setActiveTab}
            />
          ))}
        </div>
      </React.Fragment>
    );
  }
}

ShowcaseTabs.propTypes = {
  tabs: array,
  active: string,
  current: object
};

export default ShowcaseTabs;
