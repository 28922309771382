import React from "react";
import { number, array, string, shape } from "prop-types";
import LoadMoreSearchResults from "../../molecules/load-more-search-results";
import Headline from "../../atoms/headline";
import "./search.m.css";

const SearchPage = props => {
  const headline = `Results for '${props.data.query}'`;
  return (
    <div styleName="wrapper">
      <Headline headline={headline} headerLevel={2} fontStyle="sans" />
      <LoadMoreSearchResults data={props.data} />
    </div>
  );
};

SearchPage.propTypes = {
  data: shape({
    total: number,
    stories: array,
    query: string
  })
};

export { SearchPage };
