import React from "react";

const Print = () => (
  <path
    fill="#FFF"
    fillRule="nonzero"
    d="M31.143 10c.471 0 .857.375.857.833V25.73C32 28.08 30.007 30 27.591 30H12.607C10.09 30 8 27.974 8 25.495V14.687c0-1.203 1.012-2.187 2.25-2.187h5.464v-1.64a.87.87 0 0 1 .857-.86h14.572zm-15.429 4.167h-2.427c.134.307.177.646.177 1v9h2.25v-10zm-.91 13.302c.498-.459.803-1.032.883-1.636h-3.08A.848.848 0 0 1 11.75 25v-9.833c0-.552-.45-1-1.018-1-.568 0-1.018.448-1.018 1v10.328c0 .755.306 1.469.857 2.005a2.954 2.954 0 0 0 2.063.833c.787 0 1.58-.312 2.17-.864zm15.482-1.74V11.72H17.429v13.776c0 1.073-.424 2.062-1.11 2.838h11.272c.707 0 1.388-.27 1.896-.765a2.556 2.556 0 0 0 .799-1.839zM19.57 13.75h8.572v2.917H19.57V13.75zm0 4.583h8.572V20H19.57v-1.667zm0 3.334h8.572v1.666H19.57v-1.666zM28.143 25c0 .573 0 1.667-1.034 1.667h-7.966c.428 0 .428-1.667.428-1.667h8.572z"
  />
);

export default Print;
