import React from "react";
import PropTypes from "prop-types";

import "./headline.m.css";

const Headline = ({ headline, headerLevel = 1, fontStyle = "sans", headerStyle }) => {
  const HeaderTag = "h" + headerLevel;
  const styleName = HeaderTag + "-" + fontStyle;

  return (
    <HeaderTag styleName={styleName} className={`headline ${headerStyle}`}>
      {headline}
    </HeaderTag>
  );
};

Headline.propTypes = {
  headline: PropTypes.string,
  headerLevel: PropTypes.number,
  fontStyle: PropTypes.string,
  headerStyle: PropTypes.string
};

export default Headline;
