export const submitTab = {
  id: 0,
  name: "Submit Content",
  slug: "submit-content"
};

export const recentTab = {
  id: 1,
  name: "Recent",
  slug: "recent"
};
