import React from "react";
import { object, number, array, func, bool, element, string } from "prop-types";
import StoriesWithAd from "../../rows/stories-with-ad";
import "./load-more.m.css";
import { LoadMoreCollectionStories } from "@quintype/components";

const MoreCollectionStories = ({
  stories,
  onLoadMore,
  noMoreStories,
  storiesPerPage,
  withAd,
  adComponent,
  currentPath
}) => {
  return (
    <div styleName="wrapper">
      {stories.length ? (
        <StoriesWithAd
          stories={stories}
          isStickyAd={true}
          loadMore={onLoadMore}
          noMoreStories={noMoreStories}
          withAd={withAd}
          storiesPerPage={storiesPerPage}
          SideBarAdcompoenent={adComponent}
          currentPath={currentPath}
        />
      ) : (
        <h3>No stories found...</h3>
      )}
    </div>
  );
};

MoreCollectionStories.propTypes = {
  stories: array,
  onLoadMore: func,
  noMoreStories: bool,
  storiesPerPage: number,
  withAd: bool,
  adComponent: element,
  currentPath: string
};

const LoadMore = ({
  collection,
  numStoriesToLoad = 8,
  withAd = true,
  adComponent = null,
  template = null,
  loadStories,
  currentPath
}) => {
  return (
    <div>
      {loadStories ? (
        <LoadMoreCollectionStories
          template={template || MoreCollectionStories}
          collectionSlug={collection.slug}
          data={Object.assign(
            {},
            { collection },
            { stories: [...loadStories.map(({ story }) => story).slice(0, numStoriesToLoad)] },
            { storiesPerPage: numStoriesToLoad },
            { withAd },
            { adComponent },
            { currentPath }
          )}
          numStoriesToLoad={numStoriesToLoad}
          params={{ "item-type": "story" }}
        />
      ) : null}
    </div>
  );
};

LoadMore.propTypes = {
  collection: object,
  numStoriesToLoad: number,
  storiesPerPage: number,
  adComponent: element,
  template: element,
  withAd: bool,
  currentPath: string,
  loadStories: array
};

export default LoadMore;
