import React from "react";

const Globe = () => (
  <path
    fill="#FFF"
    fillRule="nonzero"
    d="M20 8C13.373 8 8 13.373 8 20s5.373 12 12 12 12-5.373 12-12S26.627 8 20 8zm-1.243 22.211a10.208 10.208 0 0 1-6.03-2.938A10.218 10.218 0 0 1 9.714 20c0-2.473.868-4.813 2.458-6.673.041.492.142 1.019.116 1.395-.094 1.373-.23 2.232.574 3.39.313.451.39 1.098.542 1.62.149.51.744.779 1.155 1.094.828.635 1.62 1.373 2.5 1.932.579.369.941.552.771 1.26-.136.569-.174.92-.469 1.426-.09.155.34 1.15.482 1.292.433.433.862.83 1.333 1.219.731.604-.07 1.389-.42 2.256zm8.516-2.938a10.212 10.212 0 0 1-5.34 2.832c.285-.705.793-1.331 1.263-1.694.41-.316.923-.924 1.137-1.405.213-.48.496-.898.783-1.34.408-.628-1.005-1.577-1.463-1.775-1.03-.448-1.805-1.051-2.72-1.695-.653-.46-1.977.24-2.713-.082-1.009-.44-1.84-1.205-2.716-1.866-.905-.68-.861-1.475-.861-2.48.708.026 1.716-.196 2.187.373.148.18.659.984 1 .698.28-.233-.207-1.168-.3-1.388-.29-.676.658-.94 1.142-1.398.632-.597 1.989-1.535 1.882-1.964-.108-.428-1.358-1.643-2.092-1.453-.11.028-1.078 1.044-1.266 1.203l.015-.994c.004-.21-.39-.424-.372-.56.046-.34.996-.96 1.232-1.232-.165-.103-.73-.588-.9-.517-.415.173-.882.291-1.296.464 0-.144-.017-.279-.038-.412a10.188 10.188 0 0 1 2.614-.758l.812.326.574.68.573.591.5.162.795-.75-.205-.535v-.481c1.572.228 3.057.814 4.357 1.719-.233.02-.488.055-.777.091-.119-.07-.272-.102-.401-.15.376.81.77 1.608 1.169 2.408.426.853 1.372 1.77 1.538 2.67.196 1.063.06 2.028.168 3.278.103 1.204 1.357 2.572 1.357 2.572s.579.197 1.06.128a10.223 10.223 0 0 1-2.698 4.734z"
  />
);

export default Globe;
