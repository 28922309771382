import React from "react";
import PropTypes from "prop-types";

import "./section-name.m.css";

const SectionName = ({ name }) => <h6 styleName="text">{name}</h6>;

SectionName.propTypes = {
  name: PropTypes.string
};

export default SectionName;
