import React from "react";
import { func, array, object } from "prop-types";
import { Link } from "@quintype/components";
import "./load-more-link.m.css";
import { getSlug } from "../../../utils";
import RightArrow from "../icons/right-arrow/index";

const LoadMoreLink = ({ sections, collection }) => {
  const slug = getSlug(collection, sections);
  if (slug === null) return null;
  return (
    <Link href={slug} styleName="load-more" className="load-more-link">
      <span styleName="text">MORE</span>
      <RightArrow color="#ad1212" width={12} height={12} />
    </Link>
  );
};

LoadMoreLink.propTypes = {
  onClick: func,
  sections: array,
  collection: object
};

export default LoadMoreLink;
