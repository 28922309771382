import React from "react";
import "./carousel-card.m.css";
import get from "lodash/get";
import CardImage from "../../atoms/card-image";
import AuthorName from "../../atoms/author-name/index";
import Headline from "../../atoms/headline";
import PropTypes from "prop-types";
import { Link } from "@quintype/components";

const CarouselCard = ({ story = {} }) => {
  const storyObj = story.story ? story.story : story;
  const authorName = get(storyObj, ["authors", 0, "name"], storyObj["author-name"]);
  const alternativeName =
    get(storyObj, ["alternative", "home", "default", "headline"], storyObj.headline) || storyObj.headline;
  const alternativeImage = get(storyObj, ["alternative", "home", "default", "hero-image", "hero-image-s3-key"], "");
  const alternativeImageMetadata = get(
    storyObj,
    ["alternative", "home", "default", "hero-image", "hero-image-metadata"],
    ""
  );

  return (
    <div styleName="card">
      <Link href={storyObj.slug}>
        <CardImage
          story={story}
          aspectRatio={[16, 9]}
          defaultWidth={330}
          type="image16x9"
          widths={[250, 330]}
          alternativeImage={alternativeImage}
          alternativeImageMetadata={alternativeImageMetadata}
        />
        <div styleName="carousel-card-info" title={alternativeName}>
          <Headline
            headline={alternativeName}
            headerLevel={2}
            fontStyle={"sans"}
            headerStyle="carousel-card-headline"
          />
          <div className="summary" styleName="summary">
            {storyObj.summary || storyObj.subheadline}
          </div>
          <div styleName="author-wrapper">
            <span styleName="author-section">By </span>
            <AuthorName name={authorName} />
          </div>
        </div>
      </Link>
    </div>
  );
};

export { CarouselCard };

CarouselCard.propTypes = {
  story: PropTypes.object
};
