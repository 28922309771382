import React from "react";
import { object } from "prop-types";
import cx from "classnames";

import PublishedAt from "../../atoms/published-at";
import StoryCard1 from "../../molecules/story-card-1";
import AdWrapper from "../../rows/ads";

import "./top-stories.m.css";

const generateStoryCards = (stories, headerLevel, className = "", showDate = false, headerStyle) => {
  const cardStyleName = cx(
    "card",
    { "mobile-card": className === "mobile" },
    { "desktop-card": className === "desktop" },
    { "stories-mobile": className === "stories-mobile" },
    { "stories-desktop": className === "stories-desktop" }
  );
  return stories.map((story, index) => {
    const publishedAtComponent = () => <PublishedAt date={story.story["published-at"]} />;
    const publishedAt = !showDate ? () => {} : publishedAtComponent;

    return (
      <div styleName={cardStyleName} key={index}>
        <StoryCard1
          story={story}
          headerLevel={headerLevel}
          wrapPublishedTimestamp={publishedAt}
          showDate={showDate}
          headerStyle={headerStyle}
        />
      </div>
    );
  });
};

const TopStoriesCollection = ({ collection }) => {
  const collectionStories = collection.items || [];
  const isStoriesPresent = collectionStories && collectionStories.length > 0;
  const firstItem = isStoriesPresent && collectionStories[0];
  const secondItem = isStoriesPresent && collectionStories[1];
  const thirdItem = isStoriesPresent && collectionStories[2];
  const fourthItem = isStoriesPresent && collectionStories[3];
  const restItems = isStoriesPresent && collectionStories.slice(4, 6);
  const lastItem = collectionStories[6];

  return (
    <div styleName="wrapper">
      {generateStoryCards([firstItem], 2, "desktop", true, "top-stories-first-item")}
      {generateStoryCards([firstItem], 2, "mobile", false, "top-stories-mobile")}
      {generateStoryCards([secondItem], 2, "mobile", false, "top-stories-mobile")}

      <div styleName="ad-wrapper" className="rhs-expand">
        <AdWrapper name="rhs1" id={`home-rhs1-web-${collection.id}`} />
      </div>

      {generateStoryCards([thirdItem], 2, "mobile", false, "top-stories-mobile")}
      {generateStoryCards([fourthItem], 2, "mobile", false, "top-stories-mobile")}

      <div styleName="ad-wrapper">
        <AdWrapper name="rhs2" id={`home-rhs2-web-${collection.id}`} />
      </div>
      {generateStoryCards(restItems, 2, "stories-mobile", false, "top-stories-mobile")}

      {lastItem && generateStoryCards([lastItem], 2, "stories-mobile", false, "top-stories-mobile")}

      {generateStoryCards([secondItem], 2, "stories-desktop", false, "top-stories-mobile")}
      {generateStoryCards([thirdItem], 2, "stories-desktop", false, "top-stories-mobile")}
      {generateStoryCards([fourthItem], 2, "stories-desktop", false, "top-stories-mobile")}
      {lastItem
        ? generateStoryCards([...restItems, lastItem], 2, "stories-desktop", false, "top-stories-mobile")
        : generateStoryCards([...restItems], 2, "stories-desktop", false, "top-stories-mobile")}
    </div>
  );
};

TopStoriesCollection.propTypes = {
  collection: object
};

export default TopStoriesCollection;
