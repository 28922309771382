import React from "react";

const NoLink = () => (
  <path
    fill="#FFF"
    d="M21.875 25.86a.371.371 0 0 1 .156.312c0 .13-.052.247-.156.351l-1.758 1.758a5.698 5.698 0 0 1-2.656 1.524 6.04 6.04 0 0 1-3.047 0 5.749 5.749 0 0 1-2.676-1.543 5.749 5.749 0 0 1-1.543-2.676 6.04 6.04 0 0 1 0-3.047 5.698 5.698 0 0 1 1.524-2.656l1.758-1.758a.486.486 0 0 1 .351-.156c.13 0 .235.052.313.156l1.562 1.563a.422.422 0 0 1 .117.312c0 .13-.039.234-.117.313l-1.758 1.757a2.712 2.712 0 0 0-.82 1.992c0 .782.273 1.446.82 1.993.547.547 1.211.82 1.993.82.78 0 1.445-.273 1.992-.82l1.758-1.758A.422.422 0 0 1 20 24.18c.13 0 .234.039.313.117l1.562 1.562zm-2.188-10.157l-1.562-1.562a.371.371 0 0 1-.156-.313c0-.13.052-.247.156-.351l1.758-1.758a5.698 5.698 0 0 1 2.656-1.524 6.04 6.04 0 0 1 3.047 0 5.749 5.749 0 0 1 2.676 1.543 5.749 5.749 0 0 1 1.543 2.676 6.04 6.04 0 0 1 0 3.047 5.698 5.698 0 0 1-1.524 2.656l-1.758 1.758a.486.486 0 0 1-.351.156.371.371 0 0 1-.313-.156l-1.562-1.563A.422.422 0 0 1 24.18 20c0-.13.039-.234.117-.313l1.758-1.757c.547-.547.82-1.211.82-1.992 0-.782-.273-1.446-.82-1.993a2.712 2.712 0 0 0-1.993-.82c-.78 0-1.445.273-1.992.82l-1.758 1.758a.422.422 0 0 1-.312.117.422.422 0 0 1-.313-.117zm9.141 14.024a.904.904 0 0 1-.664.273.904.904 0 0 1-.664-.273L10.273 12.5a.904.904 0 0 1-.273-.664c0-.26.091-.482.273-.664l.899-.899a.904.904 0 0 1 .664-.273c.26 0 .482.091.664.273L29.727 27.5a.904.904 0 0 1 .273.664c0 .26-.091.482-.273.664l-.899.899z"
  />
);

export default NoLink;
