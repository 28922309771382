import React from "react";
import { object, number } from "prop-types";
import get from "lodash/get";
import { Link } from "@quintype/components";
import { format, parseISO } from "date-fns";

import CardImage from "../../atoms/card-image";
import AuthorName from "../../atoms/author-name/index";
import SectionName from "../../atoms/section-name/index";
import Headline from "../../atoms/headline";

import "./showcase-card.m.css";

const ShowCaseCard = ({ story = {}, headerLevel = 3 }) => {
  const storyObj = story.story ? story.story : story;
  const authorId = get(storyObj, ["authors", 0, "id"], storyObj["author-id"]);
  const authorName = get(storyObj, ["authors", 0, "name"], storyObj["author-name"]);
  let sectionName = "";
  if (storyObj.sections && storyObj.sections.length) {
    sectionName = storyObj.sections[0]["display-name"] || storyObj.sections[0]["name"];
  }
  const storyHeadline = storyObj.headline;
  const sectionSlug = get(storyObj, ["sections", "0", "slug"], "");
  const alternativeName = get(storyObj, ["alternative", "home", "default", "headline"], storyHeadline) || storyHeadline;
  const alternativeImage = get(storyObj, ["alternative", "home", "default", "hero-image", "hero-image-s3-key"], "");
  const alternativeImageMetadata = get(
    storyObj,
    ["alternative", "home", "default", "hero-image", "hero-image-metadata"],
    ""
  );
  const getSponsoredStory = get(storyObj, ["metadata", "sponsored-by"], "");
  return getSponsoredStory.length <= 0 ? (
    <div styleName="wrapper">
      <Link href={`/${storyObj.slug}`}>
        <CardImage
          story={story}
          type="image16x9"
          aspectRatio={[16, 9]}
          alternativeImage={alternativeImage}
          alternativeImageMetadata={alternativeImageMetadata}
        />
        {
          <div styleName="info">
            <div>
              <span styleName="byline">By </span>
              <Link href={`/author/${authorId}`}>
                <AuthorName name={authorName} />
              </Link>
            </div>
            <Link href={`/${sectionSlug}`}>
              <SectionName name={sectionName} />
            </Link>
          </div>
        }
        <Headline headline={alternativeName} headerLevel={headerLevel} fontStyle="sans" />
        {getShowCaseItems(story)}
      </Link>
    </div>
  ) : (
    <div>
      <Link href={`/${storyObj.slug}`} styleName="image-wrapper">
        <CardImage
          story={story}
          aspectRatio={[1, 1]}
          type="image1x1"
          alternativeImage={alternativeImage}
          alternativeImageMetadata={alternativeImageMetadata}
        />
      </Link>
    </div>
  );
};

ShowCaseCard.propTypes = {
  story: object,
  headerLevel: number
};

const options = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
  timeZone: "Asia/Calcutta",
  hour: "numeric",
  minute: "numeric"
};

const getShowCaseItems = story => {
  const items = get(story, ["metadata", "story-attributes"], {});
  if (!Object.keys(items).length) return null;
  const storydate = new Date(story["published-at"]).toISOString("en-US", options);
  const date = format(parseISO(storydate), "yyyy-MM-dd");
  return (
    <div styleName="items-wrapper">
      <div>
        <span styleName="show-case-label">Brand:</span>
        <span styleName="show-case-value">{` ${items["csbrand"]}`}</span>
      </div>
      <div>
        <span styleName="show-case-label">Date: </span>
        <span styleName="show-case-value">{date}</span>
      </div>
    </div>
  );
};

ShowCaseCard.propTypes = {
  story: object
};

export default ShowCaseCard;
