import React from "react";
import { shape, number, string, bool, func } from "prop-types";
import cx from "classnames";
import { Link } from "@quintype/components";

import Icon from "./icon";

import "./tab-with-icon.m.css";

const TabWithIcon = ({ item, active, background, toggleMobileDropDown }) => {
  const style = cx("wrapper", { gray: background === "gray" }, { blue: background === "blue" });

  switch (background) {
    case "gray":
      return (
        <SelectTab
          item={item}
          style={style}
          active={active}
          background={background}
          toggleMobileDropDown={toggleMobileDropDown}
        />
      );
    default:
      return <LinkTab style={style} item={item} active={active} background={background} />;
  }
};

const SelectTab = ({ style, item, active, background, toggleMobileDropDown }) => (
  <div styleName={style} key={item.id}>
    <div styleName="icon">
      <Icon name={item.slug} active={active} background={background} />
    </div>
    <span>{item.name}</span>
    <div styleName={"toggle"} onClick={toggleMobileDropDown}>
      <span styleName="chevron-down" />
    </div>
  </div>
);

const LinkTab = ({ style, item, active, background }) => {
  const link = item.slug === "recent" ? "/creative-showcase" : `/creative-showcase/${item.slug}`;
  const url = link === "/creative-showcase/submit-content" ? "mailto:priyanka.chanana@afaqs.com" : link;
  return (
    <Link href={url} styleName={style} key={item.id}>
      <div styleName="icon">
        <Icon name={item.slug} active={active} background={background} />
      </div>
      <span>{item.name}</span>
    </Link>
  );
};

const propTypes = {
  item: shape({
    id: number,
    slug: string,
    name: string,
    route: string
  }),
  active: bool,
  background: string
};

TabWithIcon.propTypes = {
  ...propTypes,
  toggleMobileDropDown: func
};

SelectTab.propTypes = {
  ...propTypes,
  toggleMobileDropDown: func
};

LinkTab.propTypes = {
  ...propTypes
};

export default TabWithIcon;
