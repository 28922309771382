/* eslint-disable react/prop-types */
import React from "react";

const Twitter = ({ width = "24", height = "24" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <path
        fill="#1DA1F2"
        d="M19.93 8.06c.03.1.04.28.04.51 0 1.88-.46 3.69-1.37 5.43A11.27 11.27 0 0 1 8.3 20.25c-2.3 0-4.39-.61-6.29-1.84.29.03.61.04.98.04 1.9 0 3.6-.58 5.11-1.75-.9 0-1.7-.27-2.4-.8a4.08 4.08 0 0 1-1.42-2.02 5.3 5.3 0 0 0 1.84-.08 4.13 4.13 0 0 1-2.35-1.44 3.93 3.93 0 0 1-.94-2.58V9.7c.57.34 1.19.52 1.84.55a4.55 4.55 0 0 1-1.33-1.48 3.94 3.94 0 0 1-.51-1.98c0-.71.2-1.4.59-2.05A11.31 11.31 0 0 0 7.14 7.8a11.35 11.35 0 0 0 4.7 1.25 5.7 5.7 0 0 1-.07-.94 4.07 4.07 0 0 1 2.03-3.55A3.96 3.96 0 0 1 15.83 4c.6 0 1.15.12 1.66.35a4.64 4.64 0 0 1 1.35.94 8.35 8.35 0 0 0 2.61-.98 4.07 4.07 0 0 1-1.8 2.27A8.81 8.81 0 0 0 22 5.9a9.02 9.02 0 0 1-2.07 2.15z"
      />
    </svg>
  );
};

export default Twitter;
