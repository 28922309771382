import React from "react";

const VCard = () => (
  <path
    fill="#FFF"
    d="M30.083 11c.533 0 .985.188 1.358.563.373.374.559.83.559 1.366V27.07c0 .536-.186.992-.559 1.366a1.843 1.843 0 0 1-1.358.563H10.917c-.533 0-.985-.188-1.358-.563A1.866 1.866 0 0 1 9 27.072V12.93c0-.536.186-.992.559-1.367A1.843 1.843 0 0 1 10.917 11h19.166zm0 16.071V12.93H10.917V27.07h19.166zM17.306 20c-.693 0-1.292-.254-1.797-.763-.506-.51-.759-1.112-.759-1.808 0-.697.253-1.3.759-1.808.505-.51 1.104-.764 1.797-.764.692 0 1.29.255 1.796.764.506.508.76 1.111.76 1.808 0 .696-.254 1.299-.76 1.808-.505.509-1.104.763-1.796.763zm-3.594 5.143a.924.924 0 0 1-.62-.221.686.686 0 0 1-.259-.543v-.763c0-.643.26-1.192.779-1.647.52-.456 1.151-.683 1.897-.683.133 0 .306.04.519.12.426.134.852.201 1.278.201a4.24 4.24 0 0 0 1.277-.2c.24-.081.413-.121.52-.121.745 0 1.377.227 1.896.683.52.455.779 1.004.779 1.647v.763a.686.686 0 0 1-.26.543.924.924 0 0 1-.619.22h-7.187zm9.663-2.572a.304.304 0 0 1-.22-.1.308.308 0 0 1-.1-.221v-.643c0-.08.034-.154.1-.22.067-.068.14-.101.22-.101h4.472c.08 0 .153.033.22.1.066.067.1.14.1.221v.643c0 .08-.034.154-.1.221a.304.304 0 0 1-.22.1h-4.472zm0-2.571a.304.304 0 0 1-.22-.1.308.308 0 0 1-.1-.221v-.643c0-.08.034-.154.1-.221.067-.067.14-.1.22-.1h4.472c.08 0 .153.033.22.1.066.067.1.14.1.22v.644c0 .08-.034.154-.1.22a.304.304 0 0 1-.22.101h-4.472zm0-2.571a.304.304 0 0 1-.22-.1.308.308 0 0 1-.1-.222v-.643c0-.08.034-.154.1-.22.067-.068.14-.101.22-.101h4.472c.08 0 .153.033.22.1.066.067.1.14.1.221v.643c0 .08-.034.154-.1.221a.304.304 0 0 1-.22.1h-4.472z"
  />
);

export default VCard;
