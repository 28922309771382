import React, { useState, useEffect } from "react";
import cx from "classnames";
import StoryCard1 from "../../molecules/story-card-1";
import { array, bool, func, number, string } from "prop-types";
import LoadMoreButton from "../../atoms/load-more";
import AdWrapper from "../ads";
import "./stories-with-ad.m.css";

const StoriesGrid = ({ stories, currentPath }) => (
  <div styleName="cards-container">
    {stories.map((story, key) => (
      <StoryCard1
        story={story}
        key={key}
        currentPath={currentPath}
        headerStyle="stories-with-ad-headline"
        headerLevel={2}
      />
    ))}
  </div>
);

const StoryMolecule = ({ stories, showLoadMore, loadMore, currentPath }) => (
  <div>
    <StoriesGrid stories={stories} currentPath={currentPath} />
    {showLoadMore ? (
      <div styleName="button-wrapper">
        <LoadMoreButton onClick={loadMore} />
      </div>
    ) : null}
  </div>
);

const StoriesWithAd = ({
  stories,
  isStickyAd,
  noMoreStories,
  loadMore,
  storiesPerPage,
  displayLoadMore,
  withAd,
  SideBarAdcompoenent = null,
  currentPath
}) => {
  const adStyle = cx("ads", { sticky: isStickyAd });
  const showLoadMore = displayLoadMore || (stories.length >= storiesPerPage && !noMoreStories);

  const [isIPL, setIsIPL] = useState(false);

  useEffect(() => {
    const currentUrl = window.location.pathname;
    if (currentUrl === "/topic/ipl-2024" || currentUrl === "/topic/wpl-2024") {
      setIsIPL(true);
    }
  }, []);

  return (
    <React.Fragment>
      {withAd ? (
        <div styleName="container">
          <StoryMolecule stories={stories} loadMore={loadMore} showLoadMore={showLoadMore} currentPath={currentPath} />
          {isIPL ? (
            <div style={{ maxWidth: "300px", margin: "10px auto" }}>
              <AdWrapper name="IPLrhs1" id="tag-sidebar-rhs1" />
              <AdWrapper name="IPLdoubleRhs1" id="tag-sidebar-double" />
            </div>
          ) : (
            <div styleName={adStyle}>{SideBarAdcompoenent}</div>
          )}
        </div>
      ) : (
        <StoryMolecule stories={stories} loadMore={loadMore} showLoadMore={showLoadMore} currentPath={currentPath} />
      )}
    </React.Fragment>
  );
};

const propTypes = {
  stories: array,
  loadMore: func,
  noMoreStories: bool,
  storiesPerPage: number,
  currentPath: string
};

StoriesWithAd.propTypes = {
  ...propTypes,
  isStickyAd: bool,
  withAd: bool,
  displayLoadMore: bool
};

StoryMolecule.propTypes = {
  ...propTypes,
  showLoadMore: bool
};

StoriesGrid.propTypes = {
  stories: array,
  currentPath: string
};

export default StoriesWithAd;
