import React from "react";
import { func } from "prop-types";
import "./load-more.m.css";

const LoadMoreButton = ({ onClick }) => {
  return (
    <button onClick={onClick} className="load-more-button" styleName="load-more">
      LOAD MORE
    </button>
  );
};

LoadMoreButton.propTypes = {
  onClick: func
};

export default LoadMoreButton;
