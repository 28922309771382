import React from "react";
import { object } from "prop-types";
import get from "lodash/get";
import Headline from "../../atoms/headline";
import LoadMoreTagResults from "../../molecules/load-more-tag-results";
import "./tag-results.m.css";

const TagPage = props => {
  const name = get(props, ["data", "tag", "name"], "");
  const headline = `Results for '${name}'`;
  return (
    <div styleName="wrapper">
      <Headline headline={headline} headerLevel={2} fontStyle="sans" />
      <LoadMoreTagResults data={props.data} />
    </div>
  );
};

export { TagPage };
TagPage.propTypes = {
  data: object
};
