import React from "react";
import { bool } from "prop-types";

const withBackground = Component => {
  class WithBackground extends React.Component {
    render() {
      const { active, background } = this.props;
      const fill = active ? "#ad1212" : background === "blue" ? "#0e396e" : "#F3B229";

      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
          <g fill="none" fillRule="evenodd">
            <path fill={fill} d="M0 0h40v40H0z" />
            <Component {...this.props} />
          </g>
        </svg>
      );
    }
  }
  WithBackground.propTypes = {
    active: bool
  };

  return WithBackground;
};

export default withBackground;
