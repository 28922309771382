import React from "react";
import { object, number, func, string, bool } from "prop-types";
import get from "lodash/get";
import { Link, StoryElement } from "@quintype/components";

import CardImage from "../../atoms/card-image";
import AuthorName from "../../atoms/author-name/index";
import SectionName from "../../atoms/section-name/index";
import Headline from "../../atoms/headline";

import "./story-card-1.m.css";

const StoryCard1 = ({
  story = {},
  headerLevel = 3,
  wrapPublishedTimestamp,
  primaryVideo = null,
  currentPath,
  showDate = false,
  headerStyle
}) => {
  const storyObj = story.story ? story.story : story;
  const authorId = get(storyObj, ["authors", 0, "id"], storyObj["author-id"]);
  const authorSlug = get(storyObj, ["authors", 0, "slug"], "");
  const authorName = get(storyObj, ["authors", 0, "name"], storyObj["author-name"]);
  let sectionName = "";
  if (storyObj.sections && storyObj.sections.length) {
    sectionName = storyObj.sections[0]["display-name"] || storyObj.sections[0]["name"];
  }
  const storyHeadline = storyObj.headline;
  const sectionSlug = get(storyObj, ["sections", "0", "section-url"], "");
  const alternativeName = get(storyObj, ["alternative", "home", "default", "headline"], storyHeadline) || storyHeadline;
  const alternativeImage = get(storyObj, ["alternative", "home", "default", "hero-image", "hero-image-s3-key"], "");
  const currentUrl = currentPath || `${sectionSlug}`;
  const widths = showDate ? [250, 480, 764] : [250, 330];
  const alternativeImageMetadata = get(
    storyObj,
    ["alternative", "home", "default", "hero-image", "hero-image-metadata"],
    ""
  );
  return (
    <div styleName="wrapper">
      <Link
        href={
          storyObj["story-template"] !== "news-elsewhere"
            ? `/${storyObj.slug}`
            : `${storyObj.metadata["reference-url"]}`
        }
      >
        {primaryVideo ? (
          <StoryElement element={primaryVideo} story={story} />
        ) : (
          <div
            styleName={
              headerStyle === "top-stories-first-item"
                ? "hero-image-container top-stories-first-item"
                : "hero-image-container"
            }
          >
            <CardImage
              story={story}
              aspectRatio={[16, 9]}
              defaultWidth={330}
              type="image16x9"
              widths={widths}
              alternativeImage={alternativeImage}
              alternativeImageMetadata={alternativeImageMetadata}
            />
          </div>
        )}

        <div styleName="info">
          <div>
            <span styleName="byline">By </span>
            <Link href={`/author/${authorId}/${authorSlug}`}>
              <AuthorName name={authorName} />
            </Link>
            <div styleName="published-at">{wrapPublishedTimestamp && wrapPublishedTimestamp()}</div>
          </div>
          <Link href={`${currentUrl}`}>
            <SectionName name={sectionName} />
          </Link>
        </div>
        <Headline headline={alternativeName} headerLevel={headerLevel} fontStyle="sans" headerStyle={headerStyle} />
        <div styleName="summary" className="summary" title={storyObj.summary}>
          {storyObj.summary || storyObj.subheadline}
        </div>
      </Link>
    </div>
  );
};

StoryCard1.propTypes = {
  story: object,
  headerLevel: number,
  primaryVideo: object,
  wrapPublishedTimestamp: func,
  showDate: bool,
  currentPath: string,
  headerStyle: string
};

export default StoryCard1;
