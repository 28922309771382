import React from "react";
import { number } from "prop-types";
import { format, parseISO } from "date-fns";

import "./published-at.m.css";

const options = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
  timeZone: "Asia/Calcutta",
  hour: "numeric",
  minute: "numeric"
};

const PublishedAt = ({ date }) => {
  const storydate = new Date(date).toISOString("en-US", options);
  return (
    <time dateTime={new Date(date).toISOString()} styleName="published-at">
      Published: {format(parseISO(storydate), "d MMM yyyy, h:mm ")} IST
    </time>
  );
};

PublishedAt.propTypes = {
  date: number
};

export default PublishedAt;
