/* eslint-disable camelcase */
import get from "lodash/get";
import wretch from "wretch";

export const setUpAds = async (items = [], basicAds = {}, enableAd) => {
  let zoneIds = {};
  items.forEach(item => {
    let ids = get(item, ["associated-metadata", "carouselAdsZone"], "");
    if (ids.trim().length > 0) {
      ids.split(",").forEach(item => {
        const zoneId = item.trim();
        zoneIds = {
          ...zoneIds,
          [`carousel-ad-${zoneId}`]: Number(zoneId)
        };
      });
    }
  });

  const allZoneIds = { ...zoneIds, ...basicAds };
  const zoneKeys = Object.keys(allZoneIds);

  if (zoneKeys.length) {
    let zoneIds = "";
    zoneKeys.forEach(key => {
      zoneIds += escape(`${key}=${allZoneIds[key]}|`);
    });
    zoneIds += "&nz=1";
    const OA_r = Math.floor(Math.random() * 99999999);
    const url = `ads.afaqs.com/www/delivery/spc.php?zones=${zoneIds}&source=&r=${OA_r}`;

    await wretch(url)
      .get()
      .text(response => {
        const adScript = document.createElement("script");
        adScript.type = "text/javascript";
        adScript.innerHTML = response;
        document.head.appendChild(adScript);
        enableAd("CAROUSEL_AD", `refreshAd-${OA_r}`);
      });
  }
};

export const getSectionPrefix = (section, sections) => {
  let slug = section["parent-id"] === null ? section.slug : null;
  let parent;
  if (!slug && sections) {
    const parentSections = sections.length ? sections.filter(i => i["parent-id"] === null) : [];
    parent = parentSections.find(i => i.id === section["parent-id"]);
  }
  slug = parent ? parent.slug : slug;
  switch (slug) {
    case "news":
      return "articles";
    case "company-briefs":
      return "articles";
    case "people-spotting":
      return "articles";
    case "interviews":
      return "articles";
    case "shareworthy":
      return "articles";
    case "top-story":
      return "articles";
    case "media-briefs":
      return "media";
    case "creative-showcase":
      return "showCase";
    case "cmo-special":
      return "cmo";
    default:
      return "home";
  }
};

export const setAdPrefix = value => {
  return {
    type: "AD_PREFIX",
    payload: value
  };
};
