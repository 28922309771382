import React from "react";
import { array, number } from "prop-types";
import AdWrapper from "../../rows/ads";

import StoryCard1 from "../../molecules/story-card-1";

import "./two-images-with-add.m.css";

class TwoImagesWithAd extends React.PureComponent {
  generateStoryCards = items => {
    return items.map((item, index) => <StoryCard1 story={item} key={index} />);
  };

  render() {
    const { collection, id } = this.props;

    return (
      <div styleName="container">
        <div styleName="cards-container">{this.generateStoryCards(collection)}</div>
        <div styleName="ad-wrapper">
          <AdWrapper name="rhs1" id={`section-page-rhs-ad-${id}`} />
        </div>
      </div>
    );
  }
}

TwoImagesWithAd.propTypes = {
  collection: array,
  id: number
};

export default TwoImagesWithAd;
