import React, { Component } from "react";
import { object } from "prop-types";
import StoryCard1 from "../../molecules/story-card-1";
import Headline from "../../atoms/headline";

import { InfiniteScroll } from "@quintype/components";
import wretch from "wretch";

import "./infinite-stories-collection.m.css";

const removeDuplicateStories = (stories, newStories) => {
  const existingStoryIds = stories.map(story => story.id);
  return newStories.filter(story => !existingStoryIds.includes(story.id));
};

class HomeInfiniteCollection extends Component {
  constructor(props) {
    super(props);
    this.propsStories = props.collection ? props.collection.items.filter(i => i.type === "story") : [];
    this.collectionSlug = props.collection ? props.collection.slug : "";
    this.limit = 9;
    this.state = {
      stories: this.propsStories.slice(0, 9),
      pageNo: 1,
      hasMoreStories: true,
      loading: false
    };
    this.itemBase = this.itemBase.bind(this);
    this.loadMore = this.loadMore.bind(this);
  }

  itemBase({ story }) {
    return (
      <div>
        <StoryCard1 story={story} headerLevel={2} headerStyle="home-infinite-collection-headline" />
      </div>
    );
  }

  loadMore() {
    let offset = this.state.pageNo * this.limit;
    let apiUrl = `/api/v1/collections/${this.collectionSlug}?offset=${offset}&item-type=story&limit=${this.limit}`;
    if (this.state.hasMoreStories && !this.state.loading) {
      this.setState(
        {
          loading: true
        },
        () => {
          wretch()
            .url(apiUrl)
            .get()
            .json(results => {
              let newStories = results ? results.items.filter(i => i.type === "story") : [];

              this.setState({
                stories: this.state.stories.concat(removeDuplicateStories(this.state.stories, newStories)),
                loading: false,
                pageNo: this.state.pageNo + 1,
                hasMoreStories: !!newStories.length
              });
            });
        }
      );
    }
  }

  render() {
    return this.state.stories ? (
      <div styleName="wrapper">
        <div styleName="headline-container">
          <Headline headline="Latest Stories (Contd.)" headerLevel={1} fontStyle="sans" />
        </div>
        <InfiniteScroll
          render={this.itemBase}
          items={this.state.stories}
          loadNext={() => this.loadMore()}
          focusCallbackAt={10}
          onFocus={index => index}
        />
      </div>
    ) : null;
  }
}

HomeInfiniteCollection.propTypes = {
  collection: object
};

export default HomeInfiniteCollection;
