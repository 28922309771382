import React from "react";

const Clock = () => (
  <path
    fill="#FFF"
    d="M20 10.094c1.92 0 3.695.48 5.328 1.44a10.768 10.768 0 0 1 3.889 3.888 10.33 10.33 0 0 1 1.44 5.328c0 1.92-.48 3.695-1.44 5.328a10.768 10.768 0 0 1-3.889 3.889A10.33 10.33 0 0 1 20 31.407c-1.92 0-3.695-.48-5.328-1.44a10.768 10.768 0 0 1-3.889-3.889 10.33 10.33 0 0 1-1.44-5.328c0-1.92.48-3.695 1.44-5.328a10.768 10.768 0 0 1 3.889-3.889A10.33 10.33 0 0 1 20 10.093zm2.45 15.039c.114.086.243.114.386.086a.55.55 0 0 0 .344-.215l1.203-1.633a.535.535 0 0 0 .107-.387.47.47 0 0 0-.193-.343l-2.75-1.977v-5.93a.497.497 0 0 0-.15-.365.497.497 0 0 0-.366-.15H18.97a.497.497 0 0 0-.366.15c-.1.1-.15.222-.15.365v7.22a.53.53 0 0 0 .215.429l3.781 2.75z"
  />
);

export default Clock;
