import { wrapCollectionLayout, EagerLoadImages } from "@quintype/components";
import React from "react";
import TopStoriesCollection from "../rows/top-stories";
import TwoColGridWithAd from "../rows/two-col-grid-with-ad";
import TwoColGridWithLoadMore from "../rows/two-col-grid-with-load-more";
import CarouselSlider from "../molecules/carousel-slider";
import TwoImagesWithAd from "../rows/two-images-with-ad";
import ThreeColGridWithoutAd from "../rows/stories-without-ad";
import HomeInfiniteCollection from "../rows/home-infinite-scroll";

function wrapLayout(f) {
  return wrapCollectionLayout(function(props) {
    if (props.index === undefined || props.index === 0) {
      return (
        <EagerLoadImages predicate={token => token === "above-fold"}>{React.createElement(f, props)}</EagerLoadImages>
      );
    } else {
      return React.createElement(f, props);
    }
  });
}

export default {
  TopStories: wrapLayout(TopStoriesCollection),
  Carousel1: wrapLayout(CarouselSlider),
  TwoColGridWithAd: wrapLayout(TwoColGridWithAd),
  TwoColGridWithStickyAd: wrapLayout(TwoColGridWithLoadMore),
  TwoImagesWithAd: wrapLayout(TwoImagesWithAd),
  ThreeColGridWithoutAd: wrapLayout(ThreeColGridWithoutAd),
  HomeInfiniteCollection: wrapLayout(HomeInfiniteCollection),
  defaultTemplate: wrapLayout(TopStoriesCollection)
};
