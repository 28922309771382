import React from "react";

const Radio = () => (
  <path
    fill="#FFF"
    fillRule="nonzero"
    d="M9.488 13.364C8.612 13.71 8 14.626 8 15.65v14.824c0 1.361 1.067 2.473 2.4 2.473h19.2c1.333 0 2.4-1.112 2.4-2.473V15.651c0-1.372-1.067-2.472-2.4-2.472H15.558l9.911-4.13L24.656 7 9.488 13.364zm4.974 17.146a3.695 3.695 0 0 1-3.693-3.706c0-2.05 1.65-3.706 3.693-3.706a3.695 3.695 0 0 1 3.692 3.706c0 2.05-1.65 3.706-3.692 3.706zm14.769-9.92h-1.846v-2.675H24.8v2.676H10.77v-4.94h18.46v4.94z"
  />
);

export default Radio;
