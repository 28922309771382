/* eslint-disable jsx-a11y/img-redundant-alt, import/extensions */
import React from "react";
import { object, shape, string, func, array } from "prop-types";
import get from "lodash/get";
import { connect } from "react-redux";
import { setUpAds, setAdPrefix } from "../../utils/ads";
import { showHpto } from "../rows/ads";
// import { EmailSubscription } from "../rows/email-subscription";
import { getCollectionTemplate } from "../get-collection-template";
import { LazyCollection, WithPreview, replaceAllStoriesInCollection, LazyLoadImages } from "@quintype/components";

class HomePageBase extends React.Component {
  componentDidMount() {
    const basicAds = get(this.props, ["config", "publisher-attributes", "adsZone"], {});
    const items = get(this.props, ["data", "collection", "items"], []).filter(i => i.type === "collection");
    setUpAds(items, basicAds, this.props.enableAd);
    this.props.setAdPrefix("home");
    setTimeout(() => {
      const { disableAds, showHpto } = this.props;
      const disableSiteCap = disableAds.find(i => i.title === "sitecap");
      if (!disableSiteCap) {
        showHpto(true);
      }
    }, 3000);
  }

  render() {
    return (
      <div>
        <LazyLoadImages>
          <LazyCollection
            collection={this.props.data.collection}
            collectionTemplates={getCollectionTemplate}
            lazyAfter={2}
          />
          {/* <EmailSubscription /> */}
        </LazyLoadImages>
      </div>
    );
  }
}

HomePageBase.propTypes = {
  data: shape({
    collection: object
  }),
  carouselAds: string,
  enableAd: func,
  setAdPrefix: func,
  disableAds: array,
  showHpto: func
};

const mapStateToProps = state => {
  return {
    carouselAds: get(state, ["adsReducer", "carouselAds"], ""),
    disableAds: get(state, ["qt", "data", "navigationMenu", "ads"], [])
  };
};

const mapDispatchToProps = dispatch => ({
  enableAd: (type, payload) => dispatch({ type, payload }),
  setAdPrefix: payload => dispatch(setAdPrefix(payload)),
  showHpto: value => dispatch(showHpto(value))
});

const HomePage = connect(
  mapStateToProps,
  mapDispatchToProps
)(HomePageBase);

export { HomePage };

export const HomePagePreview = WithPreview(HomePageBase, (data, story) =>
  Object.assign({}, data, {
    collection: replaceAllStoriesInCollection(data.collection, story)
  })
);
