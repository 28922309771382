import React from "react";
import { object, string } from "prop-types";
import get from "lodash/get";

import Carousel from "../../atoms/carousel";
import Headline from "../../atoms/headline";
import { CarouselCard } from "../carousel-card";
import AdWrapper from "../../rows/ads";
import { connect } from "react-redux";
import LoadMoreLink from "../../atoms/load-more-link";

import "./carousel-slider.m.css";

class CarouselSliderBase extends React.Component {
  getChildren = collection => {
    const adZones = get(collection, ["associated-metadata", "carouselAdsZone"], "").split(",");

    const childrenItems = collection.items.filter(i => i.type === "story").map(story => (
      <div key={story.id}>
        <CarouselCard story={story} />
      </div>
    ));

    const { carouselAds } = this.props;
    const allItems = [];
    let adIndex = 0;
    let i = 1;

    while (i <= childrenItems.length) {
      allItems.push(childrenItems[i - 1]);
      if ((i === 2 || i % 4 === 0) && adZones.length >= adIndex) {
        const id = adZones[adIndex];
        const zoneId = id ? Number(id.trim()) : 0;
        if (zoneId !== 0) {
          const item = (
            <div styleName="adWrapper" key={`${collection.id}-${zoneId}`}>
              <AdWrapper
                name={`carousel-ad-${zoneId}`}
                id={`carousel-ad-${collection.id}-${zoneId}-${carouselAds}-${i}`}
                isCarouselAd
              />
            </div>
          );
          allItems.push(item);
        }
        adIndex++;
      }
      i++;
    }
    return allItems;
  };

  render() {
    const { collection, config } = this.props;
    const sections = get(config, ["sections"], []);
    return (
      <div styleName="carousel-container">
        <div styleName="headline-container">
          <Headline headline={collection.name} headerLevel={1} fontStyle="sans" />
          <LoadMoreLink collection={collection} sections={sections} />
        </div>

        <Carousel
          carouselName={`carousel-${collection.slug}`}
          options={{
            type: "carousel",
            perView: 2.5,
            gap: 16,
            breakpoints: { 480: { perView: 0.9, gap: 32, peek: { before: 0, after: 100 } } },
            peek: { before: 0, after: 100 }
          }}
          totalItems={collection.items.length}
          renderCenterLeftControls={({ previousSlide, currentSlide }) => {
            return (
              currentSlide !== 0 && (
                <button onClick={previousSlide} className="slider-btn">
                  <div className="arrow left" />
                </button>
              )
            );
          }}
          renderCenterRightControls={({ nextSlide }) => (
            <button onClick={nextSlide} className="slider-btn">
              <div className="arrow right" />
            </button>
          )}
        >
          {this.getChildren(collection)}
        </Carousel>
      </div>
    );
  }
}

CarouselSliderBase.propTypes = {
  collection: object,
  config: object,
  carouselAds: string
};

const mapStateToProps = state => {
  return {
    carouselAds: get(state, ["adsReducer", "carouselAds"], "")
  };
};

const CarouselSlider = connect(
  mapStateToProps,
  null
)(CarouselSliderBase);

export default CarouselSlider;
