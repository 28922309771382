import React from "react";

const TvIcon = () => (
  <path
    fill="#FFF"
    fillRule="nonzero"
    d="M28.937 12.875c.573 0 1.06.2 1.461.602.401.4.602.888.602 1.46v12.376c0 .572-.2 1.06-.602 1.46a1.989 1.989 0 0 1-1.46.602h-.688v1.375h-2.063l-.902-1.375h-10.57l-.903 1.375H11.75v-1.375h-.688c-.572 0-1.06-.2-1.46-.602A1.989 1.989 0 0 1 9 27.313V14.938c0-.573.2-1.06.602-1.461a1.989 1.989 0 0 1 1.46-.602h5.372l-1.504-1.762c-.287-.286-.43-.616-.43-.988s.136-.695.408-.967c.272-.272.595-.408.967-.408s.702.129.988.387L20 12.789l3.137-3.652c.286-.258.616-.387.988-.387s.695.136.967.408c.272.272.408.595.408.967s-.129.702-.387.988l-1.547 1.762h5.371zm-3.093 13.406l.043-.086c.057-.114.1-.3.129-.558.057-.373.1-.888.128-1.547.03-.802.043-1.79.043-2.965 0-1.174-.014-2.163-.043-2.965a15.07 15.07 0 0 0-.128-1.547c-.03-.258-.072-.444-.13-.558l-.042-.086-.13-.043c-.142-.057-.4-.1-.773-.13-.515-.056-1.231-.1-2.148-.128a167.449 167.449 0 0 0-4.168-.043c-1.49 0-2.75.014-3.781.043-.802.029-1.447.072-1.934.129-.344.029-.587.072-.73.129l-.086.043-.043.086c-.058.114-.1.3-.13.558-.056.373-.1.888-.128 1.547-.029.802-.043 1.79-.043 2.965 0 1.174.014 2.163.043 2.965.029.659.072 1.174.129 1.547.029.258.071.444.129.558l.043.086.086.043c.143.058.386.1.73.13.487.056 1.132.1 1.934.128 1.031.029 2.291.043 3.781.043 1.661 0 3.05-.014 4.168-.043a28.793 28.793 0 0 0 2.148-.129c.373-.029.63-.071.774-.129.057 0 .1-.014.129-.043zm3.093-4.297v-.343a.497.497 0 0 0-.15-.366.497.497 0 0 0-.365-.15h-.344a.497.497 0 0 0-.365.15c-.1.1-.15.222-.15.366v.343c0 .144.05.265.15.366.1.1.222.15.365.15h.344c.143 0 .265-.05.365-.15.1-.1.15-.222.15-.366zm0-2.75v-.343a.497.497 0 0 0-.15-.366.497.497 0 0 0-.365-.15h-.344a.497.497 0 0 0-.365.15c-.1.1-.15.222-.15.366v.343c0 .144.05.265.15.366.1.1.222.15.365.15h.344c.143 0 .265-.05.365-.15.1-.1.15-.222.15-.366z"
  />
);

export default TvIcon;
