import React from "react";
import { string } from "prop-types";

import withBackground from "./withBackground";
import { TvIcon, Print, VCard, Clock, Play, Radio, Globe, NoLink, Grid, Message } from "./icons";

const Icon = ({ name }) => {
  switch (name) {
    case "recent":
      return <Clock />;
    case "tv":
      return <TvIcon />;
    case "print":
      return <Print />;
    case "ooh-bhl":
      return <VCard />;
    case "digital":
      return <Play />;
    case "radio":
      return <Radio />;
    case "international":
      return <Globe />;
    case "unpublished":
      return <NoLink />;
    case "mosaic":
      return <Grid />;
    case "submit-content":
      return <Message />;
    default:
      return <Clock />;
  }
};

Icon.propTypes = {
  name: string
};

export default withBackground(Icon);
