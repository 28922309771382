import React from "react";
import "./author-bio.m.css";
import Headline from "../../atoms/headline";
import Twitter from "../../atoms/icons/twitter/index";
import PropTypes from "prop-types";

const AuthorBio = ({ authorObj }) => {
  const twitterHandleUrl = `http://www.twitter.com/${authorObj["twitter-handle"]}`;

  return (
    <div styleName="wrapper">
      <div styleName="avatar-wrapper">
        {authorObj["avatar-url"] && <img src={authorObj["avatar-url"]} alt={authorObj.name} styleName="avatar" />}
      </div>
      <div styleName="details">
        <Headline headline={authorObj.name} headerLevel={2} fontStyle={"sans"} />
        {authorObj.metadata &&
          authorObj.metadata["author-public-email"] && <p>Email: {authorObj.metadata["author-public-email"]}</p>}
        <div styleName="bio" dangerouslySetInnerHTML={{ __html: authorObj.bio }} />
        {authorObj["twitter-handle"] && (
          <a href={twitterHandleUrl} target="_blank" rel="noopener noreferrer" styleName="social">
            <Twitter />
          </a>
        )}
      </div>
    </div>
  );
};

AuthorBio.propTypes = {
  authorObj: PropTypes.object
};

export default AuthorBio;
