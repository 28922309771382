import React from "react";
import { object } from "prop-types";
import LoadMore from "../../molecules/load-more";
import AdWrapper from "../ads";

import "./styles.m.css";

const TwoColGridWithLoadMore = ({ collection }) => {
  return (
    <React.Fragment>
      <div styleName="top-ad">
        <AdWrapper id={`home-two-col-loadmore-banner-${collection.id}`} name="topBanner" />
      </div>
      <div styleName="wrapper">
        <div styleName="item1">
          <LoadMore collection={collection} withAd={false} loadStories={collection.items} />
        </div>
        <div styleName="item2">
          <div styleName="sticky">
            <AdWrapper name="doubleRhs2" id={`home-double-rhs12-${collection.id}`} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

TwoColGridWithLoadMore.propTypes = {
  collection: object
};

export default TwoColGridWithLoadMore;
