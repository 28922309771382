import React from "react";
import PropTypes from "prop-types";

const Video = ({ className = "" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} width="58" height="58">
      <g fill="none">
        <path d="M0 0h56v56H0z" />
        <g transform="translate(8 8)">
          <circle cx="20" cy="20" r="20" fill="#0E396E" stroke="#FFF" opacity=".5" />
          <path
            fill="#F3B229"
            d="M28.02 18.75c.33.18.55.44.66.76.11.33.11.66 0 .99a1.38 1.38 0 0 1-.66.76L17.2 27.65a1.39 1.39 0 0 1-.98.18 1.48 1.48 0 0 1-.88-.49 1.39 1.39 0 0 1-.35-.95V13.62c0-.41.12-.74.37-1 .24-.26.54-.41.89-.46.35-.05.66.01.95.2l10.8 6.39z"
          />
        </g>
      </g>
    </svg>
  );
};

Video.propTypes = {
  className: PropTypes.string
};

export default Video;
