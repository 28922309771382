import React from "react";
import { shape, array, object, func, number } from "prop-types";
import { connect } from "react-redux";

import LoadMore from "../../molecules/load-more";
import ShowcaseTabs from "../../molecules/showcase-tabs";
import LoadMoreButton from "../../atoms/load-more";
import ShowCaseCard from "../../molecules/showcase-card";
import { setAdPrefix } from "../../../utils/ads";

import "./showcase.m.css";

class ShowcaseBase extends React.PureComponent {
  componentDidMount() {
    this.props.setAdPrefix("showCase");
  }
  render() {
    const { data } = this.props;
    const { subSections, collection, current } = data;

    return (
      <div styleName="container">
        <div>
          <ShowcaseTabs tabs={subSections} current={current} />
        </div>
        <div styleName="load-more-wrapper">
          <LoadMore
            collection={collection}
            numStoriesToLoad={6}
            withAd={false}
            template={ShowCaseTemplate}
            loadStories={collection.items}
          />
        </div>
      </div>
    );
  }
}

const ShowCaseTemplate = ({ stories, onLoadMore, noMoreStories }) => {
  const showLoadMore = stories.length >= 6 && !noMoreStories;
  return (
    <div>
      <div styleName="cards-container">
        {stories.length ? (
          stories.map(story => <ShowCaseCard story={story} key={story.id} />)
        ) : (
          <h3>No stories found...</h3>
        )}
      </div>
      {showLoadMore ? (
        <div styleName="button-wrapper">
          <LoadMoreButton onClick={onLoadMore} />
        </div>
      ) : null}
    </div>
  );
};

ShowCaseTemplate.propTypes = {
  stories: array,
  onLoadMore: func,
  noMoreStories: number
};

ShowcaseBase.propTypes = {
  data: shape({
    sections: array,
    collection: object
  }),
  setAdPrefix: func
};

const mapDispatchToProps = dispatch => ({
  setAdPrefix: payload => dispatch(setAdPrefix(payload))
});

const Showcase = connect(
  null,
  mapDispatchToProps
)(ShowcaseBase);

export { Showcase };
