import React from "react";

const Play = () => (
  <path
    fill="#FFF"
    fillRule="nonzero"
    d="M20 8C13.377 8 8 13.377 8 20s5.377 12 12 12 12-5.377 12-12S26.623 8 20 8zm-2.4 17.4V14.6l7.2 5.4-7.2 5.4z"
  />
);

export default Play;
