import React from "react";
import { isEmpty } from "lodash";
import { shape, object, array, func, string } from "prop-types";
import get from "lodash/get";
import { connect } from "react-redux";

import LoadMore from "../../molecules/load-more";
import CarouselSlider from "../../molecules/carousel-slider/";
import TwoImagesWithAd from "../../rows/two-images-with-ad";
import Headline from "../../atoms/headline";
import AdWrapper from "../../rows/ads";
import { setUpAds, getSectionPrefix, setAdPrefix } from "../../../utils/ads";
import LoadMoreMobile from "../../rows/load-more-mobile";

import "./section.m.css";

const mobileAds = isParentSection => {
  const rhs1Ad = { name: "rhs1", id: "section-side-bar-rhs1-mobile" };
  const ads = [
    { name: "rhs2", id: "section-side-bar-rhs2-mobile" },
    { name: "rhs3", id: "section-side-bar-rhs3-mobile" },
    { name: "rhs4", id: "section-side-bar-rhs4-mobile" },
    { name: "doubleRhs1", id: "section-sidebar-double-mobile" }
  ];
  if (!isParentSection) ads.splice(0, 0, rhs1Ad);
  return ads;
};

class SectionPageBase extends React.Component {
  componentDidMount() {
    const { enableAd, setAdPrefix, sections, data } = this.props;
    const basicAds = get(this.props, ["config", "publisher-attributes", "adsZone"], {});
    const item = get(this.props, ["data", "sectionCollection"], {});
    setUpAds([item], basicAds, enableAd);
    const prefix = getSectionPrefix(data.section, sections);
    setAdPrefix(prefix);
  }

  render() {
    const { section, stories, collection, sectionCollection } = this.props.data;
    let currentPath = null;

    if (!collection.automated) {
      currentPath = this.props.currentPath;
    }

    const headline = section ? section["display-name"] || section.name : "";
    const topStories = stories.length ? stories.slice(0, 2) : [];
    const isParentSection = !section["parent-id"];
    const loadStories = isParentSection ? collection.items.slice(2) : collection.items;

    return (
      <div styleName="wrapper">
        <div styleName="headline-container">
          <Headline headline={headline} headerLevel={1} fontStyle={"serif"} />
        </div>
        {isParentSection && (
          <React.Fragment>
            <TwoImagesWithAd collection={topStories} headline={headline} id={collection.id} />
            {!isEmpty(sectionCollection) && <CarouselSlider collection={sectionCollection} />}
            <Ad />
          </React.Fragment>
        )}
        <div styleName="loadmore-wrapper-web">
          <LoadMore
            collection={collection}
            numStoriesToLoad={isParentSection ? 8 : 10}
            withAd={false}
            loadStories={loadStories}
            currentPath={currentPath}
          />
          <div>{getSideBarAds(isParentSection)}</div>
        </div>
        <div styleName="load-more-mobile">
          <LoadMoreMobile
            collection={collection}
            numStoriesToLoad={isParentSection ? 8 : 10}
            ads={mobileAds(isParentSection)}
            loadStories={loadStories}
            currentPath={currentPath}
          />
        </div>
      </div>
    );
  }
}

const getSideBarAds = isParentSection => {
  return (
    <React.Fragment>
      <div styleName="rhs-ad">{!isParentSection ? <AdWrapper name="rhs1" id="section-side-bar-rhs1" /> : null}</div>
      <div styleName="rhs-ad">
        <AdWrapper name="rhs2" id="section-side-bar-rhs2" />
      </div>
      <div styleName="rhs-ad">
        <AdWrapper name="rhs3" id="section-side-bar-rhs3" />
      </div>
      <div styleName="rhs-ad">
        <AdWrapper name="rhs4" id="section-side-bar-rhs4" />
      </div>
      <div styleName="rhs-ad">
        <AdWrapper name="doubleRhs1" id="section-sidebar-double" />
      </div>
      <div styleName="rhs-ad">
        <AdWrapper name="doubleRhs2" id="section-sidebar-double2" />
      </div>
    </React.Fragment>
  );
};

const Ad = () => (
  <div styleName="ad-section">
    <AdWrapper name="topBanner" id="section-page-banner" />
  </div>
);

SectionPageBase.propTypes = {
  data: shape({
    section: object,
    stories: array,
    collection: object,
    sectionCollection: object
  }),
  enableAd: func,
  currentPath: string,
  setAdPrefix: func,
  sections: array
};

const mapDispatchToProps = dispatch => ({
  enableAd: (type, payload) => dispatch({ type, payload }),
  setAdPrefix: payload => dispatch(setAdPrefix(payload))
});

const mapStateToProps = state => {
  return {
    sections: get(state, ["qt", "config", "sections"], [])
  };
};

const SectionPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(SectionPageBase);

export { SectionPage };
