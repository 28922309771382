import React from "react";
import { array, object } from "prop-types";
import get from "lodash/get";
import "./stories-without-ad.m.css";
import StoryCard1 from "../../molecules/story-card-1";
import LoadMoreLink from "../../atoms/load-more-link";

const ThreeColGridWithoutAd = ({ stories, collection, config }) => {
  const filteredStories = stories && stories.length ? stories.slice(0, 9) : [];
  const sections = get(config, ["sections"], []);
  return (
    <React.Fragment>
      <div styleName="wrapper">
        {filteredStories.length
          ? filteredStories.map((story, key) => (
              <StoryCard1 story={story} key={key} headerStyle="three-col-without-ad-headline" headerLevel={2} />
            ))
          : null}
      </div>
      <LoadMoreLink collection={collection} sections={sections} />
    </React.Fragment>
  );
};

ThreeColGridWithoutAd.propTypes = {
  stories: array,
  collection: object,
  config: object
};

export default ThreeColGridWithoutAd;
