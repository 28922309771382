import React from "react";
import StoriesWithAd from "../stories-with-ad";
import { array, object } from "prop-types";
import get from "lodash/get";
import "./styles.m.css";
import AdWrapper from "../ads";
import LoadMoreLink from "../../atoms/load-more-link";
import { StoriesWithAdMobile } from "../../rows/load-more-mobile";

const mobileAds = id => [
  { name: "rhs3", id: `home-rhs3-mobile-and-mobile-${id}` },
  { name: "rhs4", id: `home-rhs1-mobile-and-mobile-${id}` },
  { name: "doubleRhs1", id: `home-double-rhs-ad-mobile-${id}` }
];

const TwoColGridWithAd = ({ stories, collection, config }) => {
  const sections = get(config, ["sections"], []);
  const filteredStories = stories && stories.length ? stories.slice(0, 6) : [];
  return (
    <React.Fragment>
      <div styleName="top-ad">
        <AdWrapper id={`home-two-col-grid-banner-${collection.id}`} name="topBanner" />
      </div>
      <div styleName="wrapper-web">
        <div styleName="stories">
          <StoriesWithAd stories={filteredStories} isStickyAd noMoreStories />
        </div>

        <div styleName="ad-wrapper">
          <AdWrapper name="rhs3" id={`home-rhs3-mobile-and-web-${collection.id}`} />
          <AdWrapper name="rhs4" id={`home-rhs1-mobile-and-web-${collection.id}`} />
          <div styleName="doubleAd">
            <AdWrapper name="doubleRhs1" id={`home-double-rhs-ad-${collection.id}`} />
          </div>
        </div>
      </div>
      <div styleName="wrapper-mobile">
        <StoriesWithAdMobile stories={filteredStories} noMoreStories ads={mobileAds(collection.id)} />
      </div>
      <div styleName="load-more">
        <LoadMoreLink sections={sections} collection={collection} />
      </div>
    </React.Fragment>
  );
};

TwoColGridWithAd.propTypes = {
  stories: array,
  collection: object,
  config: object
};

export default TwoColGridWithAd;
