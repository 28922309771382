import React from "react";
import "./author-name.m.css";
import PropTypes from "prop-types";

const AuthorName = ({ name }) => {
  return <span styleName="author-name">{name}</span>;
};

export default AuthorName;

AuthorName.propTypes = {
  name: PropTypes.string
};
