import React from "react";
import get from "lodash/get";
import { array, shape, func, number, object, bool } from "prop-types";
import { LoadMoreStoriesBase } from "@quintype/components";
import StoriesWithAd from "../../rows/stories-with-ad";
import AdWrapper from "../../rows/ads";
import "./tag-results.m.css";

const storyFields = `headline,subheadline,summary,sections,tags,author-name,author-id,authors,updated-at,last-published-at,published-at,updated-at,
  first-published-at,hero-image-metadata,hero-image-s3-key,story-content-id,slug,id,seo,story-template`;

const RenderStoriesTemplate = ({ stories, onLoadMore, noMoreStories, showLoadMore }) => (
  <div styleName="stories-wrapper">
    {stories.length ? (
      <StoriesWithAd
        stories={stories}
        isStickyAd={true}
        loadMore={onLoadMore}
        storiesPerPage={10}
        noMoreStories={showLoadMore || noMoreStories}
        withAd
        SideBarAdcompoenent={<AdWrapper name="doubleRhs1" id="tag-sidebar-double" />}
      />
    ) : (
      <h3>No stories found...</h3>
    )}
  </div>
);

RenderStoriesTemplate.propTypes = {
  stories: array,
  onLoadMore: func,
  noMoreStories: number,
  showLoadMore: bool
};

const LoadMoreTagResults = ({ data }) => {
  let stories = get(data, ["stories"], []);
  let showLoadMore = true;
  if (data.stories.length > 10) {
    stories.pop();
    showLoadMore = false;
  }
  return (
    <div styleName="tag-results-wrapper">
      <LoadMoreStoriesBase
        template={RenderStoriesTemplate}
        fields={storyFields}
        params={{ "tag-slugs": data.tag.slug }}
        data={{ ...data, stories: stories, showLoadMore: showLoadMore }}
        numStoriesToLoad={8}
      />
    </div>
  );
};

LoadMoreTagResults.propTypes = {
  data: shape({
    stories: array,
    tag: object
  })
};

export default LoadMoreTagResults;
