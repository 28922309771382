import React from "react";
import { object } from "prop-types";
import StoriesWithAd from "../../rows/stories-with-ad";
import AdWrapper from "../../rows/ads";
import "./search-results.m.css";

const FIELDS =
  "author-id,author-name,created-at,first-published-at,headline,hero-image-caption,hero-image-metadata,hero-image-s3-key,id,last-published-at,published-at,sections,slug,story-content-id,story-template,tags,subheadline,summary";

class LoadMoreSearchResults extends React.Component {
  constructor(props) {
    super(props);
    const { total, stories, query } = props.data;
    this.state = {
      stories: stories,
      totalNumberOfStories: total,
      offset: stories.length,
      query: query,
      itemsToLoad: 8,
      noMoreStories: stories.length >= total
    };
  }

  loadMore = () => {
    const { itemsToLoad, offset, stories } = this.state;
    return global
      .wretch("/api/v1/search")
      .query({
        offset: offset,
        fields: FIELDS,
        limit: itemsToLoad
      })
      .get()
      .json(response => {
        const updatedResults = [].concat(stories, response.results.stories);
        this.setState({
          stories: updatedResults,
          offset: offset + itemsToLoad,
          noMoreStories: updatedResults.length >= this.state.totalNumberOfStories
        });
      });
  };

  render() {
    const { stories, noMoreStories } = this.state;
    return (
      <div styleName="search-results-wrapper">
        {stories.length ? (
          <StoriesWithAd
            stories={stories}
            isStickyAd={true}
            noMoreStories={noMoreStories}
            loadMore={this.loadMore}
            storiesPerPage={10}
            withAd
            SideBarAdcompoenent={<AdWrapper name="doubleRhs1" id="search-sidebar-double" />}
          />
        ) : (
          <h3>No stories found...</h3>
        )}
      </div>
    );
  }
}

LoadMoreSearchResults.propTypes = {
  data: object
};

export default LoadMoreSearchResults;
